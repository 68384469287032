import {
  Avatar,
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Users } from "src/context";
import Constant from "src/utils/Constant";
import ShareDialog from "src/views/coach/offers/share/new/ShareDialog";

const DropdownMenu = ({
  anchorElUser,
  handleCloseUserMenu,
  currentPlan,
  setAnchorElUser,
}) => {
  const { user } = useContext(Users);
  const navigate = useNavigate();
  const [share, setShare] = useState({
    dialog: false,
  });

  const isInPage = (key) => {
    if (window.location.href.includes("/app/settings")) {
      navigate(key);
      navigate(0);
    } else {
      navigate(key);
    }
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      elevation={0}
      PaperProps={{
        sx: {
          width: "284px",
          boxShadow: "0px 13px 30px rgba(0, 0, 0, 0.05)",
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid #EAECEF",
          mt: "8px",
        },
      }}
    >
      {/* menu header */}
      <ItemHeader currentPlan={currentPlan} />

      <Item
        name={"Share Your Link"}
        onClick={() => {
          setShare({ dialog: true });
        }}
      />

      {/* share your link dialog */}
      <ShareDialog
        open={share.dialog}
        close={() =>
          setShare({
            dialog: false,
          })
        }
        bookingURL={`${window.location.origin}/a/${user?.onboarding?.personal_link}`}
      />

      <Item
        name={"Edit Profile"}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/profile");
        }}
      />

      <Item
        name={"Upgrade Subscription"}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/billings/plans/upgrade");
        }}
      />

      <Divider sx={{ borderColor: "#EAECEF" }} />

      <Item
        name={"Billing"}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/billings");
        }}
      />

      <Divider sx={{ borderColor: "#EAECEF" }} />

      <Item
        name={"Calendar Connection"}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/settings?tab=calendar");
        }}
      />

      <Divider sx={{ borderColor: "#EAECEF" }} />

      {/* <Item
        name={"Delete Account"}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/settings?tab=calendar");
        }}
        color={"#EE6D6D"}
      /> */}

      <Item
        name={"Account Settings"}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/settings");
        }}
      />

      <Item
        name={"Logout"}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/logout");
        }}
      />
    </Menu>
  );
};

export default DropdownMenu;

const ItemHeader = ({ currentPlan }) => {
  const { user } = useContext(Users);
  return (
    <MenuItem
      sx={{
        padding: "14px 20px",
        backgroundColor: "var(--base-color-ocean-100)",
        borderRadius: "12px",
        mb: "12px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: user?.avatar ? "#FFF" : "var(--base-color-warm)",
            }}
            alt={"Treffas AB Image"}
            src={Constant.imageUrl("coach", user?.avatar)}
          >
            {user?.name?.charAt().toUpperCase()}
          </Avatar>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "normal",
              color: "#353A3F",
              textTransform: "capitalize",
            }}
          >
            {user?.name}
          </Typography>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#6E757C",
            }}
          >
            {currentPlan}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

const Item = ({ name, onClick, color = "" }) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        padding: "8px 6px 8px 12px",
        height: "36px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "6px",
        }}
      >
        <Box
          sx={{
            fontSize: "15px",
            lineHeight: "normal",
            letterSpacing: "0.3px",
            color: color ? color : "#353A3F",
          }}
        >
          {name}
        </Box>

        <Box>
          <ChevronRight size={14} color={color ? color : "#6E757C"} />
        </Box>
      </Box>
    </MenuItem>
  );
};
