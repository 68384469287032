import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import Logo from "src/components/Logo";
import SideBg from "../SideBg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import SetEmail from "./SetEmail";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";

const ResetPassword = () => {
  const [category, setCategory] = useState("email"); //email, email-sent
  const { mixpanel } = useContext(Users);
  const { t } = useTranslation();

  const Schema = Yup.object({
    email: Yup.string()
      .email(t("forgot-password.form.error.email-invalid")) //"Enter valid email address.")
      .required(t("forgot-password.form.error.email-required")),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      email: "",
    },
  });

  React.useEffect(() => {
    mixpanel.track("Reset Password Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          flexDirection: { lg: "row-reverse" },
        }}
      >
        <Box flexGrow={1}>
          {/* logo */}
          <Box
            sx={(themes) => ({
              height: "80px",
              display: {
                xs: "flex",
                lg: "none",
              },
              alignItems: "center",
              [themes.breakpoints.up("md")]: {
                alignItems: "center",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                pl: "99px",
              },
              [themes.breakpoints.down("md")]: {
                justifyContent: "flex-start",
                pl: "24px !important",
              },
            })}
          >
            <Logo />
          </Box>
          {/* folyeo login */}

          <SetEmail
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            setError={setError}
            category={category}
            setCategory={setCategory}
            getValues={getValues}
          />
        </Box>

        <SideBg />
      </Box>
    </>
  );
};

export default ResetPassword;
