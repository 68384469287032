import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import LoadingScreen from "src/components/LoadingScreen";
import PageNotFound from "src/components/PageNotFound";
import http from "src/utils/http";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginExternal = () => {
  const searchParams = useQuery();
  const token = searchParams.get("token");
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const validateToken = React.useCallback(() => {
    http
      .post("/api/auth/validate/external-token", null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data.message === "valid-token") {
          localStorage.setItem("accessToken", token);
          window.location.href = "/app/calendar";
        } else {
          setIsValid(false);
        }
      })
      .catch((err) => console.log("err:", err.message))
      .finally(() => setIsLoading(false));
  }, [token]);

  React.useEffect(() => {
    validateToken();
  }, [validateToken]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isValid) {
    return <PageNotFound />;
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 20px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Loading />
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "normal",
            color: "#6E757C",
          }}
        >
          Token validation in progress...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginExternal;
