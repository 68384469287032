import React from "react";
import ReactDOM from "react-dom/client";
import "nprogress/nprogress.css";
import "src/assets/css/folyeo.css"; /** align at top to allow other css to use the root vars */
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "cropperjs/dist/cropper.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "src/utils/i18n";
import Loading from "./components/Loading";
import { useTranslation } from "react-i18next";
import i18nResources from "./utils/i18n-resources";

const { translations } = i18nResources;

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div style={{ display: "none" }}>
      <label htmlFor="language">Select Language:</label>
      <select
        id="language"
        onChange={handleLanguageChange}
        value={i18n.language}
      >
        {translations.map((locale) => (
          <option key={locale.value} value={locale.value}>
            {locale.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Suspense fallback={<Loading />}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LanguageSwitcher />

      <Router>
        <App />
      </Router>
    </LocalizationProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
