import { Notification, PlanSubscription, Users } from "./context";
import "./App.css";
import Auth from "./auth";
import React, { useState } from "react";
import { createTheme, CssBaseline, Grow, ThemeProvider } from "@mui/material";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { ToastContainer, Zoom } from "react-toastify";
import "src/assets/css/new-static-calendar.css"; // custom css for full calendar
import "src/assets/css/custom-quill.css"; // custom css for full calendar

import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
import Constant from "./utils/Constant";
import { SnackbarContextProvider } from "./context/SnackbarContext";
import { IntegrationsContextProvider } from "./context/IntegrationsContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

/** import all the integration resource */
import MailchimpResource from "src/views/coach/integrations/v2/mailchimp/Resource";
import AweberResource from "src/views/coach/integrations/v2/aweber/Resource";
import ActiveCampaignResource from "src/views/coach/integrations/v2/active-campaign/Resource";
import ConvertkitResource from "src/views/coach/integrations/v2/convertkit/Resource";
import GetresponseResource from "src/views/coach/integrations/v2/getresponse/Resource";
import MailerLiteResource from "src/views/coach/integrations/v2/mailerlite/Resource";

import { Main } from "./Main";

const theme = createTheme({
  palette: {
    primary: {
      main: "#647889", // var(--base-color-ocean)
      light: "#8393a1", // var(--base-color-ocean-500)
      dark: "#566776", // var(--base-color-ocean-800)
    },
  },
  typography: {
    fontFamily: "var(--base-font-neuehaasroman)",
    allVariants: {
      color: "#060A2D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: "inherit",
          fontFamily: "DM Sans",
          fontSize: "15px",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,

      tcal: 1200, //custom treffas calendar size
      tbill: 922, //custom treffas billing size
      tc: 926, //custom treffas container size
      tp: 768, //custom treffas page size
      bp: 918, //custom treffas  booking page size,
      cplc: 678, //coach packages list container
      caf: 680, // create appointment form,
      ntc: 840, // new treffas container,
      invoice_container: 960,
      invoice_form: 880,
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [planSubscription, setPlanSubscription] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [userIntegrations, setUserIntegrationList] = useState([]);
  const notistackRef = React.createRef();
  const list = new Main();

  // register integration resources in app
  list.register({
    mailchimp: MailchimpResource,
    aweber: AweberResource,
    active_campaign: ActiveCampaignResource,
    convertkit: ConvertkitResource,
    getresponse: GetresponseResource,
    mailerlite: MailerLiteResource,
  });

  React.useEffect(() => {
    mixpanel.init(Constant.mixPanelKey);

    // generateIntegrations();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        // autoHideDuration={1200}
        maxSnack={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
        ref={notistackRef}
        iconVariant={{
          success: <CheckCircleOutlineIcon sx={{ marginRight: "12px" }} />,
          error: <HighlightOffIcon sx={{ marginRight: "12px" }} />,
        }}
        classes={{
          variantSuccess: "custom-snackbar-success",
        }}
      >
        <SnackbarContextProvider>
          <MixpanelProvider mixpanel={mixpanel}>
            <Users.Provider
              value={{
                user: user,
                updateUser: setUser,
                isLogin: isLogin,
                setIsLogin: (status) => setIsLogin(status),
                mixpanel: mixpanel,
                setUserIntegrationList,
                userIntegrations,
              }}
            >
              <PlanSubscription.Provider
                value={{
                  planSubscription: planSubscription,
                  setPlanSubscription: setPlanSubscription,
                }}
              >
                <Auth>
                  <Notification.Provider
                    value={{
                      notifications: notifications,
                      setNotifications: setNotifications,
                    }}
                  >
                    <IntegrationsContextProvider integrationResource={list}>
                      <Routes />
                    </IntegrationsContextProvider>
                  </Notification.Provider>
                </Auth>
              </PlanSubscription.Provider>
            </Users.Provider>
          </MixpanelProvider>
        </SnackbarContextProvider>
      </SnackbarProvider>

      {/* toaster */}
      <ToastContainer
        position="top-center"
        autoClose={1200}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={"snackbar-customize"}
        transition={Zoom}
      />
    </ThemeProvider>
  );
}

export default App;
