import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
// import LB from "src/assets/images/login-bg.png";
import LB from "src/assets/images/new-login-bg_3.png";
import Logo from "src/components/Logo";

const SideBg = () => {
  const themes = useTheme();

  return (
    <Box
      sx={{
        [themes.breakpoints.down("lg")]: {
          display: "none",
        },
        backgroundImage: `url(${LB})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#cccccc",
        backgroundSize: "cover",
        width: "560px",
        maxWidth: "560px",
        minHeight: "calc(100vh - 0px)",
        //
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mb: "70px",
        }}
      >
        <Logo />
      </Box>

      <Box
        sx={{
          minHeight: "calc(65vh - 0px)",
        }}
      >
        {/* just for space */}
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: "26px",
            fontWeight: 700,
            letterSpacing: "-0.26px",
            lineHeight: "normal",
            textAlign: "center",
          }}
        >
          Your{" "}
          <span
            style={{ color: "#0173FE", cursor: "pointer" }}
            onClick={() =>
              (window.location.href = "https://folyeo.com/features")
            }
          >
            {" "}
            complete{" "}
          </span>{" "}
          client and business management{" "}
          <span
            style={{ color: "#0173FE", cursor: "pointer" }}
            onClick={() =>
              (window.location.href = "https://folyeo.com/features")
            }
          >
            {" "}
            solution{" "}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default SideBg;
