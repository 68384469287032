import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { CircularProgress, Box, Typography } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Pdf = ({ file, isCustomize, customsize }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div style={{ "--scale-factor": 1 }}>
      <div style={{ height: "110px", overflow: "hidden" }}>
        <Document
          file={file}
          onLoadSuccess={() =>
            setTimeout(() => {
              setIsLoading(false);
            }, 3000)
          }
          loading={""} // remove default loading
          error={
            <Typography
              sx={{ color: "#878787", mt: "50px", fontSize: "12px" }}
              align="center"
            >
              Failed to load thumbnail
            </Typography>
          }
        >
          {isLoading ? (
            <Box
              sx={{
                py: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={15} />
            </Box>
          ) : (
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={isCustomize ? customsize.width : 218}
              loading={""} // remove default loading
            />
          )}
        </Document>
      </div>
    </div>
  );
};

export default Pdf;
