import { Box } from "@mui/material";
import React from "react";

const StepperGuide = ({
  length, // array of numbers length of box
  activeLength, // array of numbers active in length of box
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "4px",
      }}
    >
      {length.map((count) => {
        return (
          <Box
            key={count}
            sx={{
              backgroundColor: "#D9D9D9",
              width: "40px",
              height: "6px",
              borderRadius: "5px",
              ...(activeLength.includes(count) && {
                backgroundColor: "var(--base-color-warm-700)",
              }),
            }}
          />
        );
      })}
    </Box>
  );
};

export default StepperGuide;
