// import { Typography, useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import React from "react";
import TL from "./../assets/images/treffas_logo.png";

const Logo = () => {
  return (
    <>
      {/* medium  sc logo */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {/* <img
          src={TL}
          alt={"treffas logo"}
          style={{
            height: "30px",
            width: "182px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        /> */}
        <Typography
          className="folyeo-logo"
          sx={{
            fontSize: "40px",
          }}
        >
          Folyeo
        </Typography>
      </Box>

      {/* sm  sc logo */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        {/* <img
          src={TL}
          alt={"treffas logo"}
          style={{
            height: "20px",
            // width: "97px",
            cursor: "pointer",
            marginTop: "16px",
          }}
          onClick={() => navigate("/")}
        /> */}

        <Typography className="folyeo-logo"> Folyeo </Typography>
      </Box>
    </>
  );
};

export default Logo;
