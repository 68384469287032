import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Constant from "src/utils/Constant";
import Helper from "src/utils/Helper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TreffasLogo = () => {
  const searchParams = useQuery();
  const footer = searchParams.get("footer"); // id embeded disable on click on treffas logo
  const IsTablet = Helper.IsTablet();
  const { t } = useTranslation();

  const hideFooter = footer
    ? Constant.decrypto(decodeURIComponent(footer)) === "YES"
      ? true
      : false
    : false;

  if (hideFooter) {
    return null;
  }

  if (IsTablet) {
    return null;
  }

  // const isIframe = window.self !== window.top;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
      }}
    >
      <noscript>
        <a href={window.location.href} target="_blank">
          Please fill out the form
        </a>
      </noscript>
      <div class="treffas-webform-container">
        <Typography
          variant={"caption"}
          sx={{
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "normal",
            color: "#6E757C",
          }}
        >
          {t("powered_by")}
        </Typography>{" "}
        <a
          class="treffas-webform-inner"
          rel="nofollow"
          href="https://folyeo.com"
          target="_blank"
        >
          Folyeo
        </a>
      </div>
    </Box>
  );
};

export default TreffasLogo;
