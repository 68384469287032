const button = {
  textTransform: "inherit",
  borderRadius: 15,
  fontSize: 16,
  // padding: "12px 12px",
  lineHeight: 1.5,
  letterSpacing: 1.2,
};

const buttonHover = {
  "&:hover": {
    color: "white",
    backgroundColor: "#566776",
    borderColor: "#566776",
  },
  "&:active": {
    color: "white",
    backgroundColor: "#566776",
    borderColor: "#566776",
  },
};

const formLabel = {
  marginLeft: 1,
  fontSize: "14px",
  color: "#6F6D77",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "normal",
  fontFamily: "var(--base-font-neuehaasroman)",
  letterSpacing: "0.56px",
};

const pagination = {
  "& .MuiPaginationItem-page": {
    color: "#838383 !important",
    borderRadius: "10px",
    width: "37.55px",
    height: "32px",
    fontSize: "14px",
  },

  "& .Mui-selected": {
    color: "#fff !important",
  },
  "& .MuiPaginationItem-ellipsis": {
    color: "#000",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.25px",
  },
};

function formInput(themes) {
  return {
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      position: "relative",
      backgroundColor: themes.palette.mode === "light" ? "#fff" : "#2b2b2b",
      // border: "1px solid #A3ABBF",
      fontSize: "14px",
      height: "48px",
      padding: "0px 18px !important",
      transition: themes.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // "&:focus": {
      //   boxShadow: `${alpha(themes.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: themes.palette.primary.main,
      // },
    },
  };
}

function formInputLarge(type) {
  return {
    ...(type === "input" && {
      "& .MuiInputBase-input": {
        fontFamily: "var(--base-font-neuehaasroman)",
        fontWeight: 500,
        fontSize: "16px",
        color: "#222128",
        padding: "0px",
        paddingRight: "20px",
        paddingLeft: "20px",
        lineHeight: "normal",
        letterSpacing: "0.48px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px !important",
        height: "46px !important",
        backgroundColor: "#FFFFFF",
      },
    }),
    ...(type === "textarea" && {
      "& .MuiOutlinedInput-root": {
        padding: "10px",
        paddingRight: "20px",
        paddingLeft: "20px",
        borderRadius: "10px !important",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInputBase-input": {
        fontFamily: "var(--base-font-neuehaasroman)",
        fontWeight: 500,
        fontSize: "16px",
        color: "#222128",
        padding: "0px",
        lineHeight: "normal",
        letterSpacing: "0.48px",
      },
    }),

    ...(type === "autocomplete" && {
      "& .MuiInputBase-input": {
        fontFamily: "var(--base-font-neuehaasroman)",
        fontWeight: 500,
        fontSize: "16px",
        color: "#222128",
        padding: "0px",
        paddingRight: "20px",
        paddingLeft: "20px",
        lineHeight: "normal",
        letterSpacing: "0.48px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px !important",
        minHeight: "46px !important",
        padding: "0px",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "5px",
        paddingRight: "20px",
        paddingLeft: "20px",
        borderRadius: "10px !important",
      },
    }),
  };
}

function comfirmDialog(themes) {
  return {
    PaperProps: {
      sx: {
        [themes.breakpoints.up("md")]: {
          borderRadius: "14px",
          width: "693px",
          minHeight: "219px",
          opacity: 1,
        },
      },
    },
    BackdropProps: {
      sx: {
        backgroundColor: "#060a2dcc",
      },
    },
  };
}

const searchInput = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.01em",
  color: "#6A6E91",
  "& .MuiInput-root": {
    backgroundColor: "#FAFAFA",
    borderRadius: "14px",
    padding: "0px 12px",
  },
  "& .MuiInput-input": {
    backgroundColor: "#FAFAFA",
    borderRadius: "14px",
  },
  "& .MuiInput-input::placeholder": {
    color: "#6A6E91",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  "& .MuiInputBase-input": {
    fontSize: "15px",
    padding: "10px 12px",
  },
};

/*** tabs ***/
const tabs = {
  tab: {
    textTransform: "inherit",
    fontStyle: "normal",
    fontFamily: "var(--base-font-neuehaasmedium) !important",
    fontSize: "14px",
    lineHeight: "31px",
  },
};

/**** dropdown menut *****/
const dropdown = {
  menu: {
    elevation: 0,
    PaperProps: {
      sx: {
        // width: "120px",
        // border: "1px solid #C4C4C4",
        // borderRadius: "10px",
        // mt: "6px",
        border: "1px solid #EAECEF",
        borderRadius: "8px",
        mt: "6px",
        p: "0px",
        px: "8px",
        minWidth: "176px",
        maxHeight: "360px",
        boxShadow: "0px 16px 25px rgba(0, 0, 0, 0.07)",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },

  menuItem: {
    fontWeight: 500,
    color: "#060A2D",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "32px",
    padding: "8px",
    height: "29px !important",

    value: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "32px",
    },
    result: {
      color: "#838383",
      textAlign: { xs: "center" },
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "32px",
    },
    new: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "15px",
      color: "#19181B",
      p: "8px 12px",
      borderRadius: "6px",
      height: "36px",
    },
  },

  client_menu: {
    elevation: 0,
    PaperProps: {
      sx: {
        width: "185px",
        border: "1px solid #DBDCE1",
        borderRadius: "16px",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
  client_menu_item: {
    fontWeight: 400,
    color: "#BEBEBE",
    fontSize: "12px",
    lineHeight: "27px",
  },
};

/*** tables ***/
const table = {
  head: {
    tr: {
      "& th": {
        border: 0,
        padding: 0,
        height: "72px",
        color: "#838383",
        fontSize: "14px",
        lineHeight: "14px",
      },
    },
    th: {
      "& th": {
        border: 0,
        padding: 0,
        // height: "72px",
        fontWeight: 700,
        fontSize: "13px",
        lineHeight: "17px",
        color: "#6F6C90",
      },
    },
  },

  tbody: {
    tr: {
      "& td": {
        border: 0,
        padding: 0,
        height: "80px !important",
      },
    },
    td: {
      fontStyle: "normal",
      textTransform: "inherit",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#170F49",

      label: {
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "20px",
        textTransform: "capitalize",
      },
      caption: {
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "17px",
        color: "#A3A3A3",
        textTransform: "lowercase",
      },
    },
  },
};

const select = {
  field: {
    "& .MuiInputBase-input": {
      height: "48px",
      padding: "0px 18px !important",
      fontSize: "14px",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },

  sortText: {
    fontWeight: 500,
    color: "#BEBEBE",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "27px",
  },
};

const autocomplete = {
  field: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "48px",
      padding: "0px 18px !important",
      fontSize: "14px",
    },
  },
};

const datepicker = {
  field: {
    "& .MuiInputBase-input": {
      textTransform: "inherit",
      height: "48px",
      padding: "0px 18px !important",
      fontSize: "14px",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
};

const timepicker = {
  field: {
    "& .MuiInputBase-input": {
      textTransform: "capitalize",
      height: "48px",
      padding: "0px 18px !important",
      fontSize: "14px",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
};

const paginate = {
  item: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "32px",
  },
};

const switchs = {
  label: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "31px",
    color: "#4A5266",
  },
};

const TreffasStyle = {
  button,
  buttonHover,
  formLabel,
  formInput,
  formInputLarge,
  pagination,
  comfirmDialog,
  searchInput,
  tabs,
  dropdown,
  table,
  autocomplete,
  select,
  timepicker,
  datepicker,
  paginate,
  switchs,
};

export default TreffasStyle;
