import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import TreffasStyle from "./TreffasStyle";

const controller = new AbortController();

const toastId = 1;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  signal: controller.signal,
});

const cancel = () => {
  controller.abort();
};

instance.cancel = cancel;

instance.defaults.timeout = 150000;

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
          if (error.response.config.url === "/api/auth/me") {
          } else if (error.response.config.url === "/api/auth/login") {
          } else if (error.response.config.url === "/api/auth/logout") {
          } else {
            toast.error(<Unauthenticated />, {
              autoClose: false,
              icon: false,
              theme: "colored",
              toastId: toastId,
            });
            // toast.error(
            //   "Im Sorry. You are not authenticated, Please try logging again....",
            //   { icon: false, theme: "colored", toastId: toastId }
            // );
          }
          break;
        case 403:
          break;
        case 404:
          break;
        case 500:
          let txt = "Unable to connect to server, Please try again...";
          if (error.response.config.url === "/api/auth/me") {
            window.location.href = "/login";
          } else {
            toast.error(txt, {
              icon: false,
              theme: "colored",
              toastId: toastId,
            });
          }
          break;
        default:
          window.location.href = "/login";
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

export default instance;

function Unauthenticated() {
  const onOkay = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("organization");
    window.location.href = `${window.location.origin}/login`;
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
          }}
        >
          Im Sorry. You are not authenticated, Please try logging again....
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          onClick={() => onOkay()}
          style={{}}
          sx={{ ...TreffasStyle.buttonHover }}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
}
