import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import ShowMoreText from "react-show-more-text";
import { useTranslation } from "react-i18next";
import { Clock, Lock, Monitor, User } from "react-feather";
import DOMPurify from "dompurify";
import Constant from "src/utils/Constant";
import { useNavigate } from "react-router-dom";
import TreffasStyle from "src/utils/TreffasStyle";

const Consultations = ({
  offerList,
  coachDetails,
  display,
  header,
  footer,
}) => {
  const { t } = useTranslation();

  const planSubscription = coachDetails?.inclusive;
  const navigate = useNavigate();
  const isGroupApptsInPlan =
    planSubscription.package.session_type.group ?? false;
  const isMultipleSessionInPlan =
    planSubscription.package.availability.multiple_session ?? false;
  const isPaymentInPlan = planSubscription.package.payments ?? false;

  if (offerList.length === 0) return null;

  return (
    <Box>
      <Grid container spacing={"12px"}>
        {offerList.map((data, key) => {
          const offerType = data?.custom_options?.sessions?.configuration?.type;
          const isOfferTypeGroup = offerType === "group";
          const isMultipleSession =
            offerType === "individual" &&
            parseInt(data?.custom_options?.number_of_sessions) > 1;

          let type =
            data?.custom_options?.sessions?.configuration?.type === "individual"
              ? t("individual.booking.header.one_on_one")
              : t("individual.booking.header.group");

          let mode =
            data?.custom_options?.stripe_config?.mode === "payment"
              ? t("individual.booking.schedule.payment_first.one_time_payment")
              : data?.custom_options?.paypal_config?.mode === "payment"
              ? t("individual.booking.schedule.payment_first.one_time_payment")
              : t("individual.booking.schedule.payment_first.subscription");

          let meeting =
            data?.custom_options?.meeting_type === "onsite-meeting"
              ? t("individual.booking.header.onsite")
              : t("individual.booking.header.virtual");

          let isPackageLocked =
            (!isGroupApptsInPlan && isOfferTypeGroup) ||
            (!isMultipleSessionInPlan && isMultipleSession) ||
            (data.price > 0 && !isPaymentInPlan);

          const isLandingPageEnable =
            data?.custom_options?.landing_page?.is_enable ?? false;

          return (
            <Grid item key={key} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  gap: { xs: "0px", sm: "10px" },
                  background: "#FFFFFF",
                  border: "1px solid #DBDBDB",
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    borderRight: { sm: "1px dashed #DFE2E6" },
                    borderBottom: {
                      xs: "1px dashed #DFE2E6",
                      sm: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      p: { xs: "26px 24px 24px", sm: "24px 32px" },
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mb: "8px",
                      }}
                    >
                      <Typography
                        className="heading-4"
                        sx={{
                          fontWeight: 600,
                          letterSpacing: "0.44px",
                        }}
                      >
                        {data?.name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: {
                          xs: "flex-start",
                          sm: "center",
                        },
                        gap: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "3px 8px",
                          gap: "4px",
                          border: "1px solid #DBDBDB",
                          borderRadius: "13px",
                          minWidth: "71px",
                          height: "22px",
                        }}
                      >
                        <Box>
                          <Clock size={12} />
                        </Box>

                        <Box>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              lineHeight: "14px",
                              fontWeight: 500,
                              color: "#060A2D",
                              ...(data.status === "inactive" && {
                                color: "#838383 !important",
                              }),
                            }}
                          >
                            {data?.duration}{" "}
                            {t("individual.booking.header.min")}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "3px 8px",
                          gap: "4px",
                          border: "1px solid #DBDBDB",
                          borderRadius: "13px",
                          minWidth: "71px",
                          height: "22px",
                        }}
                      >
                        <Box>
                          <User size={12} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              lineHeight: "14px",
                              fontWeight: 500,
                              color: "#060A2D",
                              ...(data.status === "inactive" && {
                                color: "#838383 !important",
                              }),
                            }}
                          >
                            {type}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: { xs: "none", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "3px 8px",
                          gap: "4px",
                          border: "1px solid #DBDBDB",
                          borderRadius: "13px",
                          minWidth: "71px",
                          height: "22px",
                        }}
                      >
                        <Box>
                          <Monitor size={12} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              lineHeight: "14px",
                              fontWeight: 500,
                              color: "#060A2D",
                              ...(data.status === "inactive" && {
                                color: "#838383 !important",
                              }),
                            }}
                          >
                            {meeting}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        mt: "16px",
                      }}
                    >
                      <ShowMoreText
                        lines={3}
                        more={
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "#0173FE",
                            }}
                          >
                            {" Read more"}
                          </Typography>
                        }
                        less={
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "#0173FE",
                            }}
                          >
                            {" Read less"}
                          </Typography>
                        }
                        className="readmore-content-coach-offerlist-2"
                        expanded={false}
                        truncatedEndingComponent={"..."}
                      >
                        <Typography
                          className="no-margin body-md"
                          // sx={{
                          //   fontWeight: 400,
                          //   fontSize: "14px",
                          //   lineHeight: "18px",
                          //   color: "#6E757C",
                          // }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.description),
                          }}
                        />
                      </ShowMoreText>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", sm: "200px" },
                    p: { xs: "10px 24px", sm: "10px 32px" },
                    textAlign: "center",
                    display: "flex",
                    justifyContent: {
                      xs: "space-between",
                      sm: "center",
                    },
                    alignItems: "center",
                    flexDirection: { xs: "row", sm: "column" },
                  }}
                >
                  <Box
                    sx={{
                      mb: { xs: "0px", sm: "16px" },
                    }}
                  >
                    <Typography
                      className="heading-5"
                      sx={{
                        color: "#19181B",
                      }}
                    >
                      {Number(data.price) > 0
                        ? `${
                            Constant.getCurrency(
                              data.custom_options.currency ?? "USD"
                            ).symbols
                          }${data.price}`
                        : t("appointment.label.free")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        lineHeight: "14px",
                        color: "#AEB5BC",
                        fontFamily: "var(--base-font-neuehaasmedium)",
                      }}
                    >
                      {Number(data.price) > 0
                        ? mode
                        : t("appointment.label.no_payment")}
                    </Typography>
                  </Box>

                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: { xs: "100%", sm: "136px" },
                        minHeight: "44px",
                        borderRadius: "8px",
                        backgroundColor: "var(--base-color-ocean-800)",
                        color: "#FFF",
                        lineHeight: "normal",
                        "&:hover": {
                          backgroundColor: "var(--base-color-ocean-700)",
                        },
                      }}
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          display,
                          header,
                          footer,
                        });

                        const queryString = queryParams.toString();

                        let params =
                          display === "embeded" ? `?${queryString}` : ``;

                        const url = `/appointment/individual/${
                          isLandingPageEnable ? "landing-page" : "booking"
                        }/${data?.organization_id}/${data?.user_id}/${
                          data?.id
                        }${params}`;

                        navigate(url);
                      }}
                      disabled={isPackageLocked}
                      startIcon={isPackageLocked && <Lock size={15} />}
                    >
                      {isLandingPageEnable ? "Learn More" : "Book Now"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Consultations;
