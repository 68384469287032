import {
  // Button,
  // Dialog,
  // DialogContent,
  // DialogActions,
  // CircularProgress,
  Box,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Users } from "src/context";
import http from "src/utils/http";
import Loading from "./Loading";

const Logout = () => {
  // const [dialog, setDialog] = useState(true);
  const navigate = useNavigate();
  // const [isProcess, setIsProcess] = useState(false);
  const { updateUser } = useContext(Users);

  const handleLogout = async () => {
    let response = await http.post("/api/auth/logout");
    if (response.status === 200) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("organization");
      updateUser(null);
      navigate("/login");

      /* global google */
      google.accounts.id.disableAutoSelect();
    }
  };

  // const handleCancel = () => {
  //   setDialog(false);
  //   navigate(-1);
  // };

  React.useEffect(() => {
    handleLogout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "calc(100vh - 120px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </Box>
  );

  // return (
  //   <div>
  //     <Dialog
  //       open={dialog}
  //       PaperProps={{
  //         sx: {
  //           borderRadius: 4,
  //         },
  //       }}
  //     >
  //       <DialogContent>Are you sure to logout?</DialogContent>
  //       <DialogActions>
  //         <Button
  //           disabled={isProcess}
  //           startIcon={
  //             isProcess && <CircularProgress color="inherit" size={15} />
  //           }
  //           variant="contained"
  //           color={"primary"}
  //           onClick={() => handleLogout()}
  //           size={"small"}
  //         >
  //           Yes
  //         </Button>

  //         <Button
  //           variant="contained"
  //           color={"inherit"}
  //           onClick={handleCancel}
  //           size={"small"}
  //         >
  //           No
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   </div>
  // );
};

export default Logout;
