import React from "react";
import { Navigate } from "react-router-dom";
import GuestGuard from "src/components/guard/GuestGuard";
import UnGuardedBaseLayout from "src/layout/menu/unguarded";
// import BookingCancel from "src/views/general/individual-booking/cancel";
// import BookingConfirmation from "src/views/general/individual-booking/confirmation";
// import PaymentCancel from "src/views/general/individual-booking/redirects/PaymentCancel";
// import PaymentSuccessful from "src/views/general/individual-booking/redirects/PaymentSuccessful";
// import ThankyouPage from "src/views/general/individual-booking/redirects/ThankyouPage";
// import RespondentsCopy from "src/views/general/survey/respondents-copy";

import Login from "src/views/welcome/login";
import LoginExternal from "src/views/welcome/login-external";
import Register from "src/views/welcome/registration";

import { LinkedInCallback } from "react-linkedin-login-oauth2";
import SetupGooglePassword from "src/views/welcome/registration/google/SetupPassword";
import SetupLinkedInPassword from "src/views/welcome/registration/linkedin/SetupPassword";

import ResetPassword from "src/views/welcome/reset-password";
import NewPassword from "src/views/welcome/reset-password/NewPassword";
import Confirmation from "src/views/welcome/registration/verification-confirmation";
// import BookingConfirmationWithPayment from "src/views/general/individual-booking/confirmation/WithPayment";
import TwoFactor from "src/views/two-factor";

const UnguardedRoutes = [
  {
    path: "/",
    exact: true,
    guard: GuestGuard,
    layout: UnGuardedBaseLayout,
    component: () => <Navigate to="/login" />,
    show: false,
  },

  // {
  //   path: "/home",
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   component: () => <Home />,
  //   show: true,
  //   name: "Home",
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/welcome",
  //   component: () => <NewsFeed />,
  // },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/login",
    component: () => <Login />,
    show: true,
    name: "Login",
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/login/external",
    component: () => <LoginExternal />,
    show: false,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/login/auth-linkedin",
    component: () => <LinkedInCallback />,
    show: false,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/register",
    component: () => <Register />,
    show: true,
    name: "Register",
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/register/google/:googleToken",
    component: () => <SetupGooglePassword />,
    show: false,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/register/linkedin/:linkedinToken",
    component: () => <SetupLinkedInPassword />,
    show: false,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/register/verification/link",
    component: () => <Confirmation />,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/reset/password",
    component: () => <ResetPassword />,
  },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/reset/password/new",
    component: () => <NewPassword />,
  },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointments/individual/booking/confirmation/:organization_id/:user_id/:offer_id/:appointment_id/:client_id",
  //   component: BookingConfirmation,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointments/individual/booking/confirmation/with-payment",
  //   component: BookingConfirmationWithPayment,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointments/individual/booking/cancel/:organization_id/:user_id/:offer_id/:appointment_id/:client_id",
  //   component: BookingCancel,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointment-individual/booking/payment-successful",
  //   component: PaymentSuccessful,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointment-individual/booking/payment-cancelled",
  //   component: PaymentCancel,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "/appointment-individual/booking/thank-you",
  //   component: ThankyouPage,
  // },

  {
    exact: true,
    guard: GuestGuard,
    // layout: UnGuardedBaseLayout,
    path: "/two-factor/verification",
    component: TwoFactor,
    show: false,
  },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   // layout: UnGuardedBaseLayout,
  //   path: "/survey/respodent/copy/:surveyId/:respondentId/:surveySlug",
  //   component: RespondentsCopy,
  // },

  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   layout: UnGuardedBaseLayout,
  //   path: "*",
  //   component: () => <UnguardedPageNotFound />,
  //   show: false,
  // },
];

export default UnguardedRoutes;
